import { Component, OnInit, AfterViewInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service'
import { Company } from 'src/app/entities/company';
import { JwtHelperService } from '@auth0/angular-jwt';
import { CompanyService } from 'src/app/services/company.service';
import { SharedService } from 'src/app/services/shared.service';


@Component({
  selector: 'tas-top-menu',
  templateUrl: './tas-top-menu.component.html',
  styleUrls: ['./tas-top-menu.component.css']
})
export class TasTopMenuComponent implements OnInit, AfterViewInit {
  title: string = "TAS"
  user: string = ""
  username: string
  tin: string
  companyName: string
  subscriptionCompany: any;
  subscriptionToken: any;
  lang: string;
  constructor(public translate: TranslateService, private auth: AuthService, private router: Router, public jwtHelper: JwtHelperService, public sharedService: SharedService, public companyService: CompanyService) {
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('fr');
    // the lang to use, if the lang isn't available, it will use the current loader to get them
    //translate.use('en');
    this.setLang(localStorage.getItem('lang')? localStorage.getItem("lang"): "fr")

  }

  ngOnInit() {
    this.subscriptionCompany = this.sharedService.change.subscribe(company => {
      this.tin = company.tin
      this.companyName = company.companyName
    });
    this.subscriptionToken = this.sharedService.updateToken.subscribe(val => {
      if (val) {
        this.username = this.getUserFromToken();
        this.companyService.getCompany(this.username).subscribe(data => {
          if (data) {
            this.tin = data.tin
            this.companyName = data.companyName
          }
        })
      }
    })

    if (this.username = this.getUserFromToken()) {

      this.companyService.getCompany(this.username).subscribe(data => {
        if (data) {
          this.tin = data.tin
          this.companyName = data.companyName
        }
      })
    }

  }

  ngAfterViewInit() {

  }

  logout() {
    localStorage.removeItem('access_token')
    window.location.href = this.sharedService.authServerUrlLogout()
    // this.auth.logout().subscribe(data => {
    //   const promise = new Promise((resolve, reject) => {
    //     alert('removing access token')
    //     localStorage.removeItem('access_token')
    //     resolve();
    //   });
    //   promise.then((res) => {

    //     //this.router.navigate(['auth']);
    //   });
    // }
    // )
  }



  isTokenExpired() {
    return this.jwtHelper.isTokenExpired();
  }
  getUserFromToken() {
    //if (this.jwtHelper.decodeToken(localStorage.getItem('access_token'))!==null)
    if (this.jwtHelper.decodeToken(localStorage.getItem('access_token')))
      return this.jwtHelper.decodeToken(localStorage.getItem('access_token')).username;
    return null;
    //return "";
  }

  setLang(lang){
    this.sharedService.setLan(lang)
    this.lang = lang
    this.translate.use(lang)
  }
}
