import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-errorpage',
  templateUrl: './errorpage.component.html',
  styleUrls: ['./errorpage.component.css']
})
export class ErrorpageComponent implements OnInit {
  errorMessage: string;
  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    var errorCode = this.route.snapshot.paramMap.get("error")
    this.errorMessage = this.errors.find(elem => elem.code === errorCode) ? this.errors.find(elem => elem.code === errorCode)['message'] : "N/A"
  }
  
  errors = [{ "code": "404", "message": "Requested resource not found", "showErrorCode": true }]

}