import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators'
import { SharedService } from './shared.service';
import { User } from '../entities/user';
import { CompanyRepresentative } from '../entities/companyRepresentative';
const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};


@Injectable({
    providedIn: 'root'
})
export class CompanyRepresentativeService {
    constructor(private http: HttpClient, private sharedService: SharedService) { }
    getCompanyRepresentative(): Observable<CompanyRepresentative> {
        let $companyRepresentative = this.http.get<CompanyRepresentative>(this.sharedService.apiUrl('company-representative'))
        return $companyRepresentative;
    }

    sendRequest(compRep: CompanyRepresentative): Observable<any> {
        return this.http.post<any>(this.sharedService.apiUrl('company-representative/add'), compRep, httpOptions).pipe(
          tap((process: any) => this.log(`added request w/ id=${process.id}`)),
          catchError(this.handleError<any>('add'))
        );
      }

      private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
    
          // TODO: send the error to remote logging infrastructure
          console.error(error); // log to console instead
    
          // TODO: better job of transforming error for user consumption
          this.log(`${operation} failed: ${error.message}`);
    
          // Let the app keep running by returning an empty result.
          return of(result as T);
        };
      }
    
      private log(message: string) {
        //this.messageService.add('HeroService: ' + message);
      }

}