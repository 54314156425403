import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { switchMap, distinctUntilChanged } from 'rxjs/operators';
import { ReportService } from 'src/app/services/report.service';
import { ProcessService } from 'src/app/services/process.service';
import { ToastrService } from 'ngx-toastr';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-external-forms',
  templateUrl: './external-forms.component.html',
  styleUrls: ['./external-forms.component.css']
})
export class ExternalFormsComponent implements OnInit {
  jsonObj: any
  key = 'page-1-key'
  name = 'New Form'
  type = 'page'
  processKey
  processName
  mcasReportId
  reportId
  stageData
  id
  reportStartDate
  reportOverdue
  processStatus
 
  constructor(public processService: ProcessService, private reportService: ReportService, private route: ActivatedRoute) { 
    
  }

  
  ngOnInit() {
   

       this.initialize()
  }

  initialize(){
    this.route.paramMap.pipe(switchMap((params: ParamMap) => {
      this.mcasReportId = params.get('mcasreportId')
      this.reportId = params.get('reportId')
      return this.reportService.getReportById(decodeURIComponent(this.reportId).split('?')[0])
    }))
      .subscribe(report => {
        this.route.queryParams
        .subscribe(paramsQuery => {
          this.reportStartDate = paramsQuery['start_date']
          this.reportOverdue = paramsQuery['overdue']
        }) 
        this.processService.getProcessByMcasReportId(this.mcasReportId).subscribe(proc => {
          if (proc===null){
            var completeProcess = {
              'key': report.key,
              'name': report.name,
              'stages': [],
              'status':'In Progress (Draft)',
              'progress':Math.floor(Math.random() * 99) + 1,
              'external':true,
              'mcasReportId':this.mcasReportId
            };
            this.processService.addProcess(completeProcess).subscribe
              (data => {
                this.id =  data['id']
                this.processStatus = 'In Progress (Draft)'
              });
          }else{
            this.stageData = proc['stages'][0];
            this.id =  proc['id']
            this.processStatus = proc['status']
          }
          
          this.processKey = report.key
          this.processName = report.name
          var stagePage = report.stages[0]
          this.jsonObj = JSON.parse(JSON.stringify(stagePage))
          this.key = this.jsonObj.key
          this.name = this.jsonObj.name
          this.type = this.jsonObj.type
        })
      });



  }

}
