import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-category-table',
  templateUrl: './category-table.component.html',
  styleUrls: ['./category-table.component.css']
})
export class CategoryTableComponent implements OnInit {
  @Input()
  data: any;
  columns: any;
  categories: any;
  constructor() {

  }

  ngOnInit() {
    this.processJsonStructure(this.data);
    this.orderCategories(this.data.data_structure, 0)
  }
  processJsonStructure(data) {
    this.columns = [];
    for (const column of this.data.columns) {
      this.columns.push(column)
    }
    this.categories = [];
    for (const category of this.data.data_structure) {
      this.categories.push(category);
    }
  }

  buildCategoryChain(category) {

    if (category.categories !== undefined) {
      for (const subcategory of category.categories) {
        return this.buildCategoryChain(subcategory)
      }
    }
    return category;
  }
  mCategories = [];
  orderCategories(rows, level) {
    var space = ""
    for (var num = 0; num < level; num++) {
      space += "-"
    }
    for (var num = 0; num < rows.length; num++) {
      if (rows[num].categories !== undefined) {
        this.mCategories.push({ 'name': rows[num].name, 'type': 'not_final', 'level': level + 3 })
        this.orderCategories(rows[num].categories, level + 1)
      } else {
        this.mCategories.push({ 'name': rows[num].name, 'type': 'final', 'level': level + 8 })
      }
    }
  }

  counterTill(number) {
    var returnArray = []
    var num: number = 0;
    for (num = 0; num < number; num++) {
      returnArray.push(num)
    }

    return returnArray;
  }
}
