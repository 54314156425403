import { Component, EventEmitter, OnInit, Output, AfterViewInit, OnDestroy } from "@angular/core";
import { Input, OnChanges, SimpleChanges, SimpleChange } from "@angular/core";
import { FileService } from "../../services/file.service";
import { MatCheckbox, MatRadioButton, MatDialog } from "@angular/material";
import { ThousandSeparatorPipe } from "src/app/pipes/thousand-separator.pipe";
import { FileUploader } from "ng2-file-upload";
import { SharedService } from "src/app/services/shared.service";
import { NgxSpinnerService } from "ngx-spinner";
import { ProcessService } from "src/app/services/process.service";
import { saveAs } from "file-saver";
import { ConfirmationDialogComponent } from "../dialogs/confirmation-dialog/confirmation-dialog.component";
import { InfoDialogComponent } from "../dialogs/info-dialog/info-dialog.component";
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from "@angular/material-moment-adapter";
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from "@angular/material/core";
import * as _moment from "moment";
import { element, CIRCULAR } from "@angular/core/src/render3/instructions";
import { TranslateService } from "@ngx-translate/core";
// tslint:disable-next-line:no-duplicate-imports
@Component({
  selector: "tas-table",
  templateUrl: "./tas-table.component.html",
  styleUrls: ["./tas-table.component.css"],
  providers: [
    // The locale would typically be provided on the root module of your application. We do it at
    // the component level here, due to limitations of our example generation script.
    { provide: MAT_DATE_LOCALE, useValue: "fr" },

    // `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing
    // `MatMomentDateModule` in your applications root module. We provide it at the component level
    // here, due to limitations of our example generation script.
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
})
export class TasTableComponent implements OnInit {
  subscriptionUpdateForm: any;
  @Input() processKey;
  @Input() processId;
  @Input() processName;
  @Input() processYear;
  @Input() stageKey;
  @Input() stageData;
  @Input() globals = {};
  @Output() onSelectedValue = new EventEmitter<any>();
  @Output() onRowKeyValue = new EventEmitter<any>();
  @Input() stage: any;
  @Input() selectedBlk: any;
  @Input() isSubmitted: any;
  @Input() reportId: any;
  @Input() mcasreportId: any;
  dataSourceOld = [];
  dataSources = [];
  originalData = [];
  categoryTableData: any;
  categoryTableColumns = [];
  originalDisplayedColumns = null;
  inputValues = {};
  attachments = {};
  backingData = [];
  requiredFields = [];
  stageOutput: any;
  globalsOutput: any;
  multiColumn: boolean;
  editMode = false;
  thisYear = new Date().getFullYear();
  mCategories = [];
  multiColumns = [];
  categoryHeader = "";
  isExtendable = {};
  curlyBrackets = "{{";

  data = {
    thisYear: this.thisYear,
    previousYear: this.thisYear - 1,
    previousYearProcesses: {
      dsf: {},
    },
    processes: {
      dsf: this.inputValues,
    },
  };
  columnNames = {
    name: "Name",
    key: "Value",
  };

  columnStyles = {};

  columnNames2 = {
    name: "Name",
    key: "Value",
  };
  header_created = false;

  backingDataCounter = 0;

  clr = 1;
  allowedMimeType = [".xls", ".xlsx", ".doc", ".docx", ".pdf", "image/jpeg", "image/png"];
  public uploader: FileUploader;
  fileNames = {};
  fileNameLinks = {};
  fileInDocflow = {};
  public uploaders = {};
  subscriptionLanguage: any;
  constructor(
    private fileService: FileService,
    private thousandSeparatorPipe: ThousandSeparatorPipe,
    public sharedService: SharedService,
    public translateService: TranslateService,
    private spinner: NgxSpinnerService,
    public processService: ProcessService,
    public dialog: MatDialog,
    private _adapter: DateAdapter<any>
  ) {
    this.subscriptionLanguage = sharedService.getLan$().subscribe((lan) => {
      if (lan) {
        if (lan === "fr") {
          this._adapter.setLocale("fr");
        }
        if (lan === "en") {
          this._adapter.setLocale("en-US");
        }
      }
    });
  }

  keytab(event) {
    if (event.key === "Enter") {
      // let element = event; // get the sibling element
      // if (element == null) {
      //   return;
      // } // check if its null
      // else {
      //   element.next(':input').focus()
      // }
    }
  }

  getColor() {
    if (this.clr === 1) {
      this.clr = 0;
      return "#e6e6e6";
    }
    this.clr = 1;
    return "lightgrey";
  }

  processStageStructure() {
    for (const block of this.stage.blocks) {
      var dataSource1 = [];
      this.originalData = [];
      var displayedColumns = ["name"];
      var upperHeaderColumns = [];
      var upperHeaderColumns2 = [];
      if (block.columns === undefined || block.columns.length === 0) {
        for (const row of block.rows) {
          this.originalData.push({
            name: row.name,
            key: row.key,
            default: row.default,
            enabled: row.default,
            style: row.style,
            type: row.type,
            required: row.required,
            global: row.global,
            hasAttachment: row.hasAttachment,
          });
          if (row.hasAttachment) {
            var uploader = new FileUploader({
              url: this.sharedService.apiUrl("upload"),
              authToken: "Bearer " + localStorage.getItem("access_token"),
              allowedFileType: ["xls", "xlsx", "pdf", "doc", "docx", "image"],
              maxFileSize: 15 * 1024 * 1024,
              itemAlias: "dec",
              additionalParameter: {
                fileType: "attachment",
                processId: this.processId,
                processYear: this.processYear,
                processName: this.processName,
                stage: this.stage.key,
                row: row.key,
                mcasreportId: this.mcasreportId ? this.mcasreportId : null,
                reportId: this.reportId !== null ? this.reportId : null,
              },
            });
            uploader.onWhenAddingFileFailed = (item, filter) => {
              uploader.clearQueue();
              this.fileNames[row.key] = undefined;
              if (filter.name === "fileSize") {
                this.openFileSizeLimitExceededDialog();
              }
            };
            uploader.onAfterAddingFile = (fileItem) => {
              this.fileNames[row.key] = fileItem.file.name;
              this.uploaders[row.key]["options"]["additionalParameter"]["stagedata"] = this.stageOutput;
            };
            uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
              this.uploaders[row.key].clearQueue();
              this.fileNames[row.key] = undefined;
              const resp = JSON.parse(response);
              this.attachments[row.key] = { file: resp["file"], filename: resp["filename"] };

              this.spinner.hide();
            };
            this.uploaders[row.key] = uploader;
          }

          if (this.stageData !== undefined) {
            var v = this.stageData.rows.find((col) => col[row.key] !== undefined && col[row.key] !== "");
            if (v !== undefined) {
              this.inputValues[row.key] = v[row.key];
              this.stageOutput.rows.push({ [row.key]: v[row.key] });
              if (row.required !== undefined && row.required === true) {
                if (this.requiredFields.indexOf(row) !== -1) this.requiredFields.splice(this.requiredFields.indexOf(row), 1);
              }
            }
          }
          if (row.global) {
            for (var rowG in this.globals) {
              if (rowG === row.key) {
                this.inputValues[row.key] = this.globals[rowG];

                if (this.stageOutput.rows.find((el) => el[row.key] !== undefined)) {
                  this.stageOutput.rows.find((el) => el[row.key] !== undefined)[row.key] = this.globals[rowG];
                } else {
                  this.stageOutput.rows.push({ [row.key]: this.globals[rowG] });
                }
              }
            }
          }
          if (row.required !== undefined && row.required === true) {
            if (this.inputValues[row.key] === undefined || this.inputValues[row.key] === null || this.isEmptyOrSpaces(this.inputValues[row.key])) {
              this.requiredFields.push(row.key);
            }
          }
        }
        this.multiColumns[block.key] = false;
      } else {
        for (const row of block.rows) {
          const sourceRow = { name: row.name, key: row.key, default: row.default, enabled: row.default, style: row.style, bold: row.bold };
          this.inputValues[row.key] = {};
          if (this.stageData !== undefined) {
            var v = this.stageData.rows.find((cols) => cols["key"] === row.key);
            if (v !== undefined) {
              for (const value of v.values) {
                var objKey = Object.keys(value)[0];
                this.inputValues[row.key][objKey] = value[objKey];
                var vxs = this.stageOutput.rows.find((cols) => cols["key"] === row.key);
                if (vxs !== undefined) {
                  vxs.values.push({ [objKey]: value[objKey] });
                } else {
                  this.stageOutput.rows.push({ key: row.key, values: [{ [objKey]: value[objKey] }] });
                }
              }
            }
          }
          for (const value of row.values) {
            if (sourceRow["required"] === undefined) {
              sourceRow["required"] = [];
            }
            sourceRow["required"][value.column] = value.required;
            if (value.required !== undefined && value.required === true) {
              if (this.inputValues[row.key][value.column] === undefined || this.isEmptyOrSpaces(this.inputValues[row.key][value.column])) {
                this.requiredFields.push(row.key + "." + value.column);
              }
            }

            if (sourceRow["types"] === undefined) {
              sourceRow["types"] = [];
            }
            sourceRow["types"][value.column] = value.type;

            if (sourceRow["fontWeight"] === undefined) {
              sourceRow["fontWeight"] = [];
            }
            sourceRow["fontWeight"][value.column] = value.bold ? "bold" : value.fontWeight;

            if (value.formula === undefined) sourceRow[value.column] = value.value;
            else {
              sourceRow[value.column] = value.formula;
              sourceRow["style"] = "bold";
            }
          }
          this.originalData.push(sourceRow);
        }

        for (const column of block.columns) {
          displayedColumns.push(column.key);
          this.columnNames[column.key] = column.name;
          this.columnStyles[column.key] = column.bold ? "800" : "normal";
          if (column.isExtendable) {
            this.isExtendable[block.key] = true;
          }
        }
        if (block.colType === "2") {
          displayedColumns.push("action#");
        }
        if (block.columnGroups !== undefined) {
          var grpCounter = 0;
          for (const column of block.columnGroups) {
            upperHeaderColumns[grpCounter] = column.name;
            grpCounter++;
          }
        }
        if (block.columnGroups2 !== undefined) {
          var grpCounter = 0;
          for (const column of block.columnGroups2) {
            upperHeaderColumns2[grpCounter] = column.name;
            grpCounter++;
          }
        }
        this.multiColumns[block.key] = true;
      }
      dataSource1 = this.originalData.filter((elem) => {
        return elem.enabled === true;
      });

      if (block.colType === "2" && this.stageData) {
        for (let x = 0; x < this.stageData.rows.length; x++) {
          let r = this.stageData.rows[x];
          if (r.key) {
            let spl = r.key.split("#####");
            if (spl.length > 1 && block.key === spl[0]) {
              this.stageOutput.rows.push({key: r.key, values: r.values})
              let result = r.values.reduce((obj, item) => {
                for (let key in item) {
                  obj[key] = item[key];
                }
                return obj;
              }, {});
              this.inputValues[r.key] = result;
              let cloneDatasource = Object.assign([], dataSource1);
              let m = cloneDatasource[cloneDatasource.length - 1];
              let newRow = Object.assign({}, m);
              newRow.key = r.key;
              cloneDatasource.push(newRow);
              dataSource1 = cloneDatasource;
            }
          }
        }
        
      }
      
      this.dataSources.push({
        datasource: dataSource1,
        colType: block.colType,
        displayedColumns: displayedColumns,
        multiColumn: this.multiColumns[block.key],
        blockKey: block.key,
        blockName: block.name,
        columnGroups: block.columnGroups,
        upperHeaderColumns: upperHeaderColumns,
        columnGroups2: block.columnGroups2,
        upperHeaderColumns2: upperHeaderColumns2,
      });
    }
    this.stageOutput["status"] = this.requiredFields.length === 0 ? "done" : "processing";
    this.stageOutput["name"] = this.stage.name;
    this.stageOutput["globals"] = this.globals ? this.globals : {};
    this.onSelectedValue.emit(this.stageOutput);
  }

  processPreviousYearJSON(data) {
    for (const stage of data.stages) {
      for (const row of stage.rows) {
        if (row.key === undefined) continue;
        for (const value of row.values) {
          this.data.previousYearProcesses.dsf[row.key] = {};
          this.data.previousYearProcesses.dsf[row.key][Object.keys(value)[0]] = value[Object.keys(value)[0]];
        }
      }
    }
  }
  uploadAttachment(uploader) {
    this.spinner.show();
    uploader.uploadAll();
  }
  addDynamicRow(dt, blockKey) {
    let newKey = blockKey + "#####" + this.generateRandomString(10);
    this.inputValues[newKey] = {};
    let cloneDatasource = Object.assign([], dt.datasource);
    let m = cloneDatasource[cloneDatasource.length - 1];
    let newRow = Object.assign({}, m);
    newRow.key = newKey;
    cloneDatasource.push(newRow);
    dt.datasource = cloneDatasource;
  }
  removeAddable(dt, rowInd) {
    let cloneDatasource = Object.assign([], dt.datasource);
    let elk = cloneDatasource[rowInd].key;
    let sto = this.stageOutput.rows.find((elem) => elem.key === elk);
    if (sto) this.stageOutput.rows.splice(this.stageOutput.rows.indexOf(sto), 1);
    dt.datasource = [];
    cloneDatasource.splice(rowInd, 1);
    dt.datasource = cloneDatasource;
  }
  generateRandomString(length) {
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let randomString = "";

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      randomString += characters.charAt(randomIndex);
    }

    return randomString;
  }
  ngOnInit() {
    if (this.processId) {
      this.processService.getAttachments(this.processId, this.stageKey).subscribe((attachments) => {
        this.attachments = {};
        attachments.forEach((att) => {
          for (var key in att[this.stageKey]) {
            this.attachments[key] = att[this.stageKey][key];
          }
        });
        this.initialize();
      });
    } else {
      this.initialize();
    }
  }

  initialize() {
    this.stageOutput = {
      key: this.stageKey,
      rows: [],
    };
    this.processStageStructure();
    this.onSelectedValue.emit(this.stageOutput);
  }

  clearFromUploads(rowKey, fileInput) {
    this.fileNames[rowKey] = undefined;
    this.uploaders[rowKey].clearQueue();
    fileInput.value = "";
  }
  downloadFileFromDocFlow(fileObj) {
    this.spinner.show();
    this.processService.downloadFileFromDocflow(fileObj.file).subscribe((data) => {
      this.spinner.hide();
      const blob = data;
      const file = new Blob([blob], {});
      const filename = fileObj.filename;
      saveAs(file, filename);
    });
  }

  deleteFileEmmitter: EventEmitter<boolean> = new EventEmitter();
  subscriptionConfirm: any;
  openDeleteFileDialog(fileObj, rowId) {
    var prc = { p_id: this.processId, p_stage: this.stageKey, p_row: rowId };
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: "500px",
      data: { text: "DIALOG.PROCESS_DELETE_ATTACHMENT", emitter: this.deleteFileEmmitter, obj: fileObj.file },
    });

    this.subscriptionConfirm = this.sharedService.confirmDialogEmitter.subscribe((val) => {
      if (val) {
        this.spinner.show();
        this.processService.deleteFile(val).subscribe((data) => {
          this.processService.deleteAttachment(prc).subscribe((delRes) => {
            this.attachments[rowId] = undefined;
            this.spinner.hide();
          });
        });
      }
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }

  openFileSizeLimitExceededDialog() {
    const dialogRef = this.dialog.open(InfoDialogComponent, {
      width: "500px",
      data: { title: "DIALOG.ATTACHMENT_FILE_SIZE_EXCEEDED" },
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }

  renderMustache(template, index, row, col) {
    if (!template) return;
    if (template.indexOf("{{") === -1) return index === 0 ? "" : template;
    const string = template.replace(/{{\s?([^{}\s]*)\s?}}/g, (substring, parsedKey) => {
      const replacer = parsedKey.split(".").reduce((o, i) => {
        if (o !== undefined) {
          return o[i];
        }
      }, this.inputValues);
      return typeof replacer !== "undefined" && replacer !== "" && !isNaN(replacer) ? replacer : 0;
    });
    if (string === template || string.indexOf("{{") !== -1) {
      return null;
    } else {
      try {
        var res = eval(string);
        if (isNaN(res)) {
          return res;
        } else {
          this.inputValues[row["key"]][col] = res;

          return res;
        }
      } catch (e) {
        return this.translateService.instant("PROCESS.SUBMITED_FILE_REPORT");
        // return 'Incorrect value in input field.';
      }
    }
  }
  a = 0;
  getToggleColor() {
    if (this.a === 0) {
      this.a = 1;
      return "#6699ff";
    }
    this.a = 0;
    return "white";
  }
  arrangeRequiredField(required, rowKey, event) {
    if (this.requiredFields.find((elem) => elem === rowKey) !== undefined) {
      if (this.requiredFields.indexOf(rowKey) !== -1) this.requiredFields.splice(this.requiredFields.indexOf(rowKey), 1);
    } else if (required && (this.getEventValue(event) === null || this.isEmptyOrSpaces(this.getEventValue(event)))) {
      this.requiredFields.push(rowKey);
    }
  }

  onChange(rowElem, col = null, multiColumn, event) {
    let row = rowElem["key"];
    let required = col !== null ? (rowElem["required"][col] !== undefined ? rowElem["required"][col] : false) : rowElem["required"] !== undefined ? rowElem["required"] : false;
    let pushed = false;
    let pushedGlobal = false;
    if (!multiColumn) {
      this.arrangeRequiredField(required, row, event);
      let val = this.getEventValue(event);
      for (const stageRow of this.stageOutput.rows) {
        if (stageRow[row] !== undefined) {
          stageRow[row] = this.getEventValue(event);
          pushed = true;
          if (val === null || val === "" || val === undefined) {
            this.stageOutput.rows.splice(this.stageOutput.rows.indexOf(stageRow), 1);
          }
          break;
        }
      }

      if (!pushed) {
        this.stageOutput.rows.push({
          [row]: this.getEventValue(event),
        });
      }

      if (rowElem["global"]) {
        // if (this.stageOutput.globals === undefined) {
        //   this.stageOutput['globals'] = []
        // }
        for (const stageRow of this.stageOutput.globals) {
          if (stageRow[row] !== undefined) {
            stageRow[row] = this.getEventValue(event);
            pushedGlobal = true;
            break;
          }
        }
        if (!pushedGlobal) {
          // this.stageOutput.globals.push({
          //   [row]: this.getEventValue(event)
          // });

          this.stageOutput.globals[row] = this.getEventValue(event);
        }
      }
    } else {
      this.arrangeRequiredField(required, row + "." + col, event);

      const rowToBeChanged = this.originalData.find((elem) => {
        return elem.key === row;
      });

      var options = [];
      if (rowToBeChanged)
        for (var x in rowToBeChanged.types) {
          if (rowToBeChanged.types[x] === "option" && x !== col) options.push(x);
        }

      for (const stageRow of this.stageOutput.rows) {
        if (stageRow["key"] === row) {
          for (const stageValue of stageRow.values) {
            if (stageValue[col] !== undefined) {
              stageValue[col] = this.getEventValue(event);
              pushed = true;
            }
          }
          if (!pushed) {
            if (event.source instanceof MatRadioButton) {
              stageRow.values.forEach((val) => {
                options.forEach((opt) => {
                  if (val[opt] !== undefined) {
                    var idxx = stageRow.values.indexOf(val);
                    stageRow.values.splice(idxx, 1);
                  }
                });
              });
              stageRow.values.push({
                [col]: this.getEventValue(event),
              });
            } else {
              stageRow.values.push({
                [col]: this.getEventValue(event),
              });
            }
            pushed = true;
          }
        }
      }
      if (!pushed) {
        if (event.source instanceof MatRadioButton) {
          this.stageOutput.rows.push({
            key: row,
            values: [
              {
                [col]: this.getEventValue(event),
              },
            ],
          });
        } else {
          this.stageOutput.rows.push({
            key: row,
            values: [
              {
                [col]: this.getEventValue(event),
              },
            ],
          });
        }
      }
    }

    for (var obj in this.inputValues) {
      var val = this.inputValues[obj];
      if (this.isObject(val)) {
        var arr = this.stageOutput.rows.find((elem) => elem["key"] === obj);
        if (arr)
          for (var keyInVal in val) {
            var elInArr = arr.values.find((elem) => elem[keyInVal]);
            let x = {};
            x[keyInVal] = val[keyInVal];
            if (!elInArr) {
              arr.values.push(x);
            } else {
              arr.values[arr.values.indexOf(elInArr)] = x;
            }
          }
      }
    }
    this.stageOutput["status"] = this.requiredFields.length === 0 ? "done" : "processing";
    this.stageOutput["name"] = this.stage.name;
    this.onSelectedValue.emit(this.stageOutput);
  }

  isObject(value) {
    return value && typeof value === "object" && value.constructor === Object;
  }
  // edit() {
  //   if (!this.editMode) {
  //     this.editMode = true;
  //     this.dataSourceOld = this.originalData;
  //     this.originalDisplayedColumns = this.displayedColumns;
  //     this.displayedColumns = ['default', 'name'];
  //   } else {
  //     this.editMode = false;
  //     this.dataSourceOld = this.originalData.filter(elem => {
  //       return elem.enabled === true;
  //     });
  //     this.displayedColumns = this.originalDisplayedColumns;
  //     this.originalDisplayedColumns = null;
  //   }
  // }

  ngOnChanges(changes: SimpleChanges) {
    const stage: SimpleChange = changes.stage;
  }
  isEmptyOrSpaces(str) {
    const stringValue = str.toString();
    return stringValue === null || stringValue.match(/^ *$/) !== null;
  }

  checkboxClick(event, key) {
    const rowToBeChanged = this.originalData.find((elem) => {
      return elem.key === key;
    });
    rowToBeChanged.enabled = event.checked;
    if (event.checked === false) {
      if (!this.multiColumn) {
        for (const row of this.stageOutput.rows) {
          if (row[key] !== undefined) {
            delete row[key];
          }
        }
      } else {
        for (let i = 0; i < this.stageOutput.rows.length; i++) {
          if (this.stageOutput.rows[i].key === key) {
            delete this.stageOutput.rows[i];
          }
        }
      }
      Object.keys(this.inputValues[key]).forEach((col) => {
        this.inputValues[key][col] = 0;
      });
    }
  }

  onAmountChange(amount: string, name) {}

  getEventValue(event) {
    return event.source instanceof MatCheckbox ? event.checked : event.source instanceof MatRadioButton ? event.value : event.target !== undefined ? event.target.value : event;
  }

  printKey(key, col) {
    this.onRowKeyValue.emit("{{" + key + "." + col + "}}");
  }
}
