import { Component, EventEmitter, OnInit, Input, Output, OnDestroy, AfterViewInit, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FileService } from '../../services/file.service';
import { ProcessService } from '../../services/process.service';
import { createPipeInstance } from '@angular/core/src/view/provider';
import { ReportService } from 'src/app/services/report.service';
import { Observable, zip } from 'rxjs';
import { async } from 'q';
import { JwtHelperService } from '@auth0/angular-jwt';
import { saveAs } from 'file-saver';
import { ToastrService } from 'ngx-toastr';
import { SharedService } from 'src/app/services/shared.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DialogData } from '../home/home.component';
import { YearService } from 'src/app/services/year.service';
import { ConfirmationDialogComponent } from '../dialogs/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'multi-step-form',
  templateUrl: './multi-step-form.component.html',
  styleUrls: ['./multi-step-form.component.css']
})
export class MultiStepFormComponent implements OnInit, OnDestroy {
  @Input()
  processKey: string;  
  @Input()
  processName: string;
  @Input()
  stageName: string;
  @Input()
  processYear: string;
  @Input()
  processStages;
  @Input()
  processStageData
  @Input()
  globals
  @Input()
  currentStage: any;
  @Input()
  saveForm;
  @Input()
  formCreation: boolean;
  @Input()
  status: string;
  @Input()
  external: any;
  ccData: any;
  processId;
  stages: any;
  output: any;
  isFirstStage: boolean;
  isLastStage: boolean;
  username: string;
  saveSubmitReportSubscription: any
  isActive = false;
  @Output() onRowKeyValue = new EventEmitter<any>();
 
  width = "60%"
  completeProcess = {
    'key': '',
    'name': '',
    'year': '',
    'globals': {},
    'stages': []
  };
  @Input()
  selectedBlk: any;
  reportId: any;
  mcasreportId: any;
  isSubmitted = false;

  constructor(private route: ActivatedRoute, private fileService: FileService, private reportService: ReportService,
    private processService: ProcessService, public router: Router, public jwtHelper: JwtHelperService,  private toastr: ToastrService, 
    private sharedService: SharedService, public yearService: YearService,
    public dialog: MatDialog
    ) {
    this.processKey = this.route.snapshot.paramMap.get('processKey');
    this.processId = this.route.snapshot.paramMap.get('id');
    this.reportId =  this.route.snapshot.paramMap.get('reportId')
    this.mcasreportId =  this.route.snapshot.paramMap.get('mcasreportId')
    this.saveSubmitReportSubscription = sharedService.saveSubmitReportEmmiter.subscribe(() => { 
      this.saveAndSubmitReport(false)
    }) 
  }
  firstStageForm = {};
  processJSON(data) {
    this.completeProcess.key = data.key
    this.completeProcess.name = data.name
    this.completeProcess.year = this.processYear
    if (data.mcasReportId && data.external) {
      this.completeProcess['mcasReportId'] = data.mcasReportId
      this.completeProcess['external'] = data.external
      this.completeProcess['reportStartDate'] = new Date(data.reportStartDate)
      this.completeProcess['reportOverdue'] = new Date(data.reportOverdue)
      this.processId = data.id;
      this.isSubmitted = this.status==='Submitted' || false
    }
    this.stages = [{ name: this.currentStage.name, key: this.currentStage.key }];
  }
  setStg = 0;
  changeStage(value) {
    if (this.setStg===0){
      this.setStg = 1;
      this.firstStageForm = JSON.parse(JSON.stringify(value));
    }
    this.currentStage = value
    this.completeProcess.globals = value['globals']
  }
  proceedEmitter: EventEmitter<boolean> = new EventEmitter(); 
  stageComplete(end) {
      if (!this.diff(this.currentStage,this.firstStageForm) && this.status !== 'Submitted'){
        var saveobj = {}
        saveobj['FORM_SAVE'] = true;
        saveobj['FORM_END'] = end;
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, { 
          width: '500px', 
          data: { text: "DIALOG.SAVE_AND_PROCEED", emitter: this.proceedEmitter, obj: saveobj } 
        }); 
      }else{
        this.setStg = 0;
        var indexOfCurrentStage = this.ccData.stages.indexOf(this.ccData.stages.find(elem => elem.key === this.currentStage.key))
        this.isFirstStage = false;
        this.generateStagePage(indexOfCurrentStage + 1)
        window.scrollTo(0, 0)
      }
  
    
  }

  diff(obj1, obj2) {
    return JSON.stringify(obj1) === JSON.stringify(obj2)
}

  goBack() {
    this.setStg = 0;
    var indexOfCurrentStage = this.ccData.stages.indexOf(this.ccData.stages.find(elem => elem.key === this.currentStage.key))
    this.generateStagePage(indexOfCurrentStage - 1)
    if (indexOfCurrentStage - 1 === 0) {
      this.isFirstStage = true;
    } else {
      this.isFirstStage = false;
    }
    window.scrollTo(0, 0)
  }


  back() {
    this.router.navigate(['process/' + this.processId]);
  }
  saveDraftProcess(redirect) {
    if (this.processId === undefined || this.processId === null) {
      this.completeProcess['status'] = 'In Progress (Draft)';
    }
    this.completeProcess['progress'] = Math.floor(Math.random() * 99) + 1;
    this.completeProcess.stages = [];
    this.completeProcess.stages.push(this.currentStage);
    this.completeProcess.stages.forEach(elem => delete elem.globals)
    this.saveProcess(redirect)
  }
  submitReport = false;
  saveAndSubmitReport(redirect) {
    this.submitReport = true;
    this.saveDraftProcess(redirect);
  }

  saveProcess(redirect) {
    if (this.processId) {
      this.completeProcess['id'] = this.processId
    }
    if (this.submitReport === true) {
      this.completeProcess['status'] = 'Submitted'
    }
    this.processService.addProcess(this.completeProcess).subscribe
      (data => {
        if (this.external) {
          this.processId = data.id
          if (this.submitReport === true) {
            this.submitExternalReport(this.external['key'],this.completeProcess['mcasReportId'])
            this.isSubmitted = true;
            this.submitReport = false;
            this.toastr.success('Success!', `Report successfully submitted`, {
              toastClass: "toast border-red",
              timeOut: 3000
            });
          } else {
            if (this.saveForm==="undefined"){
              this.toastr.success('Success!', `Report successfully saved`, {
                toastClass: "toast border-red",
                timeOut: 3000
              });
            }
            this.saveForm=undefined;
            
          }
        }
        else {
          if (redirect) {
            this.router.navigate(['process/' + data.id]);
          }
          else {
            var indexOfCurrentStage = this.ccData.stages.indexOf(this.ccData.stages.find(elem => elem.key === this.currentStage.key))
            this.isFirstStage = false;
            this.generateStagePage(indexOfCurrentStage + 1)
          }
        }

      });
  }
  subscriptionSave: any 
  subscriptionDontSave: any 
  ngOnInit() {
    this.startInit();
    this.yearService.getList("year").subscribe(val =>{
      
      val.forEach(element => {
        if (element.year===this.processYear){
          this.isActive = element.isActive
        }
      });
    })
    this.subscriptionSave= this.sharedService.confirmDialogEmitter.subscribe(val => { 
      if (val['FORM_SAVE']){
        if (val && val['FORM_END']&&val['FORM_END']===true) 
        {
          this.saveDraftProcess(true)
        }else{
          this.setStg=0;
          this.saveDraftProcess(false)
          window.scrollTo(0, 0)
        }
      }
    }) 
    this.subscriptionDontSave= this.sharedService.confirmProceedDialogEmitter.subscribe(val => {
      if (val['FORM_END']&&val['FORM_END']===true) 
      {
        this.router.navigate(['process/' + this.processId]);
      }else{
        var indexOfCurrentStage = this.ccData.stages.indexOf(this.ccData.stages.find(elem => elem.key === this.currentStage.key))
        this.isFirstStage = false;
        this.generateStagePage(indexOfCurrentStage + 1)
        window.scrollTo(0, 0)
        this.setStg = 0;
      }
    
  }) 
  }

  startInit(){
    this.username = this.getUserFromToken();
    if (this.processKey !== null) {
      this.reportService.getPages(this.processKey)
        .subscribe(
          data => {
            var indexOfCurrentStage = data.stages.indexOf(data.stages.find(elem => elem.key === this.currentStage.key))
            this.ccData = data;
            if (this.external !== undefined) {
              this.processJSON({ external: true, key: this.external['key'], name: this.external['name'], mcasReportId: this.external['mcasReportId'], id: this.external['procId'], reportStartDate: this.external['reportStartDate'],reportOverdue: this.external['reportOverdue']})
            } else {
              this.processJSON(data);
            }
            if (indexOfCurrentStage === 0) {
              this.isFirstStage = true;
            } else {
              this.isFirstStage = false;
            }
            if (data.stages.indexOf(data.stages.find(elem => elem.key === this.currentStage.key)) === data.stages.length - 1) {
              this.isLastStage = true;
            } else {
              this.isLastStage = false;
            }
          },
          err => {
            console.log(err);
          }
        );
    }
    else {
      if (this.external !== undefined) {
        this.processJSON({ external: true, key: this.external['key'], name: this.external['name'], mcasReportId: this.external['mcasReportId'], id: this.external['procId'], 
        reportStartDate: this.external['reportStartDate'],
        reportOverdue: this.external['reportOverdue']
      })
      this.sharedService.saveForm(this.processId);
      }
      else {
        this.processJSON({ key: 'temp', name: 'temp' })
      }

    }
  }

  getInputId(event) {
    this.onRowKeyValue.emit(event)
  }

  submitExternalReport(name,mcasID) {
    this.processService.uploadExternalReport(name, this.processId, mcasID).subscribe
      (data => {
       const blob = data;
       const file = new Blob([blob], {});
       const filename = this.getUserFromToken()+'_'+name+'_'+ Date.now() + '.pdf';
       saveAs(file, filename);
      });
  }

  generateStagePage(indexOfCurrentStage) {
    if (this.ccData.stages.length > indexOfCurrentStage) {
      if (this.ccData.stages.length === indexOfCurrentStage + 1) {
        this.isLastStage = true;
      } else {
        this.isLastStage = false;
      }
    }
    let process$ = this.processService.getProcess(this.processId)
    let structure$ = this.reportService.getReportPage(this.processKey, this.ccData.stages[indexOfCurrentStage].key)
    let zipSubscription$ = zip(process$, structure$)
    zipSubscription$.subscribe(result => {
      let processData = result[0]
      let processStructure = result[1]
      this.currentStage = processStructure;
      this.stages = [{ name: this.currentStage.name, key: this.currentStage.key }];

      if (processData !== null) {
        if (processData['stages'].find(stage => stage !== null && stage.key === this.currentStage['key']) !== undefined) {
          this.processStageData =
            processData['stages'].find(stage => stage !== null && stage.key === processStructure.key)
        } else {
          if (this.processStageData !== undefined)
            this.processStageData.rows = []
        }
        this.globals = processData['globals'] ? processData['globals'] : {}

      } else {
        this.globals = {}
      }
    });
  }
  openInEditor() {
    this.router.navigate(['/formcreation', this.processKey, this.currentStage.key]);
  }

  getUserFromToken() {
    return this.jwtHelper.decodeToken(localStorage.getItem('access_token')).username;
  }
  openSubmitReportDialog(): void {
    const dialogRef = this.dialog.open(SubmitReportProcessDialog, {
      width: '400px'
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }

  ngOnDestroy(): void {
    if (this.saveSubmitReportSubscription) {
      this.saveSubmitReportSubscription.unsubscribe();
    }
    if (this.subscriptionSave) {
      this.subscriptionSave.unsubscribe();
    }
  }

}


@Component({
  selector: 'submit-report-confirmation-dialog',
  templateUrl: 'submit-report-dialog.html',
})
export class SubmitReportProcessDialog {
  processId: string;
  constructor(
    public dialogRef: MatDialogRef<SubmitReportProcessDialog>,
    private sharedService: SharedService) {
    
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  saveAndSubmitReport() {
    this.dialogRef.close();
    this.sharedService.saveSubmitReport();
  }

}