import { Company } from "./company";
import { CompanyRepresentativeStatus } from "./states/companyRepresentativeStatus";

export class CompanyRepresentative {
    public id: number
    public company: Company
    public name: string
    public username: string
    public surname: string
    public email: string
    public status: CompanyRepresentativeStatus
    public address: string
    public telephone : string
    public type: string; 
    public tin: string;
    public registrationNumber: string
    public incorportionDate: Date
    public city: string
    public country: string
    public postcode: string
    public website: string
    public description: string
    public owner: string
    public ownerCreation: boolean
    
    constructor() {
   
    }
  
  }  