 
 import { CompanyRepresentativeStatus } from './states/companyRepresentativeStatus';
 
  
const CompanyRepresentativesStatusName = new Map<number, string>([
  [CompanyRepresentativeStatus.REQUESTED, 'REQUESTED'],
  [CompanyRepresentativeStatus.ACTIVE, 'ACTIVE'],
  [CompanyRepresentativeStatus.DECLINED, 'DECLINED'],
  [CompanyRepresentativeStatus.SUSPENDED, 'SUSPENDED']
]); 

var replaceValues = (obj1: any, obj2: any) => {
  for (var key in obj1) {
    delete obj1[key]
  }
  for (var key in obj2) {
    obj1[key] = obj2[key]
  }
}

export { replaceValues,  CompanyRepresentativesStatusName }