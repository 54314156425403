import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { MatPaginator, MatTableDataSource } from '@angular/material';

@Component({
  selector: 'app-tas-datatable',
  templateUrl: './tas-datatable.component.html',
  styleUrls: ['./tas-datatable.component.css']
})
export class TasDatatableComponent implements OnInit {
  @Input()
  dataSource = new MatTableDataSource();
  
  constructor() { }

  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
  }

}
