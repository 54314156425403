export class JsonGenerator {

    generateAPI(jsonObj) {
        var jsonObjAPI = {}
        for (var i = 0; i < jsonObj.blocks.length; i++) {
            var block = jsonObj.blocks[i]
            for (var b = 0; b < block.rows.length; b++) {
                var row = block.rows[b]
                if (row.values !== undefined) {
                    var values = row.values
                    for (var v = 0; v < values.length; v++) {
                        var apiVal = this.getApiValue(values[v])
                        if (apiVal !== undefined) {
                            if (jsonObjAPI[row.key] === undefined) {
                                jsonObjAPI[row.key] = {}
                            }
                            jsonObjAPI[row.key][values[v]['column']] = apiVal
                        }
                    }
                } else {
                    if (row.style === undefined || row.style==='') {
                        jsonObjAPI[row.key] = this.getApiValue(row)
                    }
                }
            }
        }
        return jsonObjAPI;
    }

    getApiValue(row) {
        if (row.type === 'string') {
            return 'sample_value';
        }
        if (row.type === 'number') {
            return 1234;
        }
        if (row.type === 'date') {
            return '2018-11-21'
        }
        if (row.type === 'checkbox') {
            return true;
        }
        if (row.type === 'option') {
            return row.column;
        }
        return undefined;
    }

}