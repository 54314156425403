import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { SharedService } from './shared.service';
const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};


@Injectable({
    providedIn: 'root'
})
export class ProcessTypeService {

    constructor(private http: HttpClient, private sharedService: SharedService) { }

    getProcessTypes(year) {
        let processTypes$ = this.http.get<any>(this.sharedService.apiUrl('process-types/'+year))
        return processTypes$;
    }

    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {

            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // TODO: better job of transforming error for user consumption
            this.log(`${operation} failed: ${error.message}`);

            // Let the app keep running by returning an empty result.
            return of(result as T);
        };
    }

    private log(message: string) {
        //this.messageService.add('HeroService: ' + message);
    }
}
