import { Component, OnInit, AfterViewInit, Inject, ViewChild } from '@angular/core';
import { ProcessService } from '../../../services/process.service';
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import { Process } from '../../../entities/process';
import { FileService } from '../../../services/file.service';
import { switchMap } from 'rxjs/operators';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ReportService } from 'src/app/services/report.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { saveAs } from 'file-saver';
import { FileUploader } from "ng2-file-upload";
import { InfoDialogComponent } from '../../dialogs/info-dialog/info-dialog.component';
import { SharedService } from 'src/app/services/shared.service';

export interface DialogData {
  name: string;
}
@Component({
  selector: 'app-process-view',
  templateUrl: './process-view.component.html',
  styleUrls: ['./process-view.component.css']
})
export class ProcessViewComponent implements OnInit {
  public uploader: FileUploader;
  fileName: string;
  currentProcessId: number;

  public uploaderTables: FileUploader;
  fileNameTables: string;
  // stages coming from the process structure
  stages = [];
  process: Process;
  canSubmit = false;
  missingStages = []
  allowedMimeType = ['.xls', '.xlsx'];
  hasMapping = false;
  reportFiles: any;
  @ViewChild('fileUpload') selectedFile: any;
  constructor(public processService: ProcessService, public reportService: ReportService, private router: Router, private route: ActivatedRoute,
    private fileService: FileService, public dialog: MatDialog, private spinner: NgxSpinnerService, public sharedService: SharedService) { }

  ngOnInit() {
    this.process = new Process();
    this.route.paramMap.pipe(switchMap((params: ParamMap) => {

      this.uploader = new FileUploader({
        url: this.sharedService.apiUrl('upload'), authToken: "Bearer " + localStorage.getItem('access_token'),
        allowedFileType: ["xls", "xlsx"],
        maxFileSize: 50 * 1024 * 1024,
        itemAlias: 'dec', additionalParameter: { 'processId': params.get('id') }
      });
      this.uploader.onWhenAddingFileFailed = (item) => {
        this.uploader.clearQueue();
        this.fileName = undefined
      }
      this.uploader.onAfterAddingFile = (fileItem) => {
        this.fileName = (fileItem.file.name);
        this.uploader['options']['additionalParameter']['excelType'] = 'original'
        this.uploader['options']['additionalParameter']['processName'] = this.process.name
        this.uploader['options']['additionalParameter']['processYear'] = this.process.year
      }
      this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
        this.uploader.clearQueue();
        var responsePath = JSON.parse(response);
        setTimeout(() => {
          if (!responsePath.success) {
            var errMessage = responsePath.unhandled ? responsePath.message : "DIALOG." + responsePath.message
            const dialogRef = this.dialog.open(InfoDialogComponent, {
              width: '500px',
              data: { title: errMessage }
            });
            dialogRef.afterClosed().subscribe(result => {
            });
          }
          else {
            this.processService.getProcess(params.get('id')).subscribe(process => {
              this.populatePages(process)
            }, err => { console.log(err) });
          }
          this.fileName = undefined
          this.spinner.hide();
        }, 3000)
      };




      this.uploaderTables = new FileUploader({
        url: this.sharedService.apiUrl('upload'), authToken: "Bearer " + localStorage.getItem('access_token'),
        allowedFileType: ["xls", "xlsx"],
        maxFileSize: 50 * 1024 * 1024,
        itemAlias: 'dec', additionalParameter: { 'processId': params.get('id') }
      });
      this.uploaderTables.onWhenAddingFileFailed = (item) => {
        this.uploaderTables.clearQueue();
        this.fileNameTables = undefined
      }
      this.uploaderTables.onAfterAddingFile = (fileItem) => {
        this.fileNameTables = (fileItem.file.name);
        this.uploaderTables['options']['additionalParameter']['excelType'] = 'tables'
        this.uploaderTables['options']['additionalParameter']['processName'] = this.process.name
        this.uploaderTables['options']['additionalParameter']['processYear'] = this.process.year
      }
      this.uploaderTables.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
        this.uploaderTables.clearQueue();
        var responsePath = JSON.parse(response);
        setTimeout(() => {
          if (!responsePath.success) {
            var errMessage = responsePath.unhandled ? responsePath.message : "DIALOG." + responsePath.message
            const dialogRef = this.dialog.open(InfoDialogComponent, {
              width: '500px',
              data: { title: errMessage }
            });
            dialogRef.afterClosed().subscribe(result => {
            });
          }
          else {
            this.processService.getProcess(params.get('id')).subscribe(process => {
              this.populatePages(process)
            }, err => { console.log(err) });
          }
          this.fileNameTables = undefined
          this.spinner.hide();
        }, 3000)
      };


      return this.processService.getProcess(params.get('id'))
    }))
      .subscribe(process => {
        this.reportService.getReportFiles(process['key'],process['year']).subscribe(val=>{
        this.reportFiles = val;
        })

        this.processService.getProcessMapping(process['key']).subscribe(result=>{
          this.hasMapping=(result!==null);
          this.populatePages(process)
        })
       
        
      
      
      }, err => { console.log(err) });
  }

  isEmpty(obj) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key))
        return false;
    }
    return true;
  }
  downloadFile(name){
    this.spinner.show();
    this.processService.downloadFile(name).subscribe
      (data => {
        this.spinner.hide();
        const blob = data;
        const file = new Blob([blob], {});
        const filename = name;
        saveAs(file, filename);
      });
  }
  populatePages(process) {
    this.process = process;
    this.currentProcessId = this.process.id;
    this.reportService.getPages(process['key'])
      .subscribe(
        data => {
          this.stages = data.stages;
          var stgs = this.stages.filter(elem => elem.required === true).map(a => a.key);
          var x = this.process['stages'].map(a => a.key);
          for (var stg in stgs) {
            if (!x.includes(stgs[stg])) {
              this.missingStages.push(this.stages.find(elem => elem.key === stgs[stg])['name'])
            }
          }
          if (this.stages.length === this.process['stages'].length) {
            if (this.process['stages'].find(elem => elem.status === 'processing') === undefined) {
              this.canSubmit = true;
            }
          }
        },
        err => {
          console.log(err);
        }
      );
  }
  ngAfterViewInit() {

  }
  deleteProcess() {
    if (confirm("Are you sure to delete " + this.process.id)) {
      console.log("Implement delete functionality here");
    }
  }

  list() {
    this.router.navigate(['home']);
  }

  downloadPdf(name) {
    this.spinner.show();
    this.processService.downloadPDF(name, this.process.id).subscribe
      (data => {
        this.spinner.hide();
        const blob = data;
        const file = new Blob([blob], {});
        const filename = 'document-' + Date.now() + '.pdf';
        saveAs(file, filename);
      });
  }

  downloadExcel(name) {
    this.spinner.show();
    this.processService.downloadExcel(name, this.process.id).subscribe
      (data => {
        this.spinner.hide();
        const blob = data;
        const file = new Blob([blob], {});
        const filename = 'document_' + Date.now() + '.xlsx';
        saveAs(file, filename);
      });
  }

  getColorByStatus(stageKey) {
    var stageInQuestion = this.process['stages'].find(element => element.key === stageKey)
    if (stageInQuestion === undefined) {
      return '#e3e8e5';
    }
    else {
      if (stageInQuestion.status === 'processing') {
        return '#757ce8'; //violet
      }
      return '#18a028';
    }
  }

  getTextColorByStatus(stageKey) {
    var stageInQuestion = this.process['stages'].find(element => element.key === stageKey)
    if (stageInQuestion === undefined) {
      return '#000501';
    }
    else {
      return '#f7fbf8';
    }
  }

  downloadFileFromDocFlow(name, ext) {
    this.spinner.show();
    this.processService.downloadFileFromDocflow(name).subscribe
      (data => {
        this.spinner.hide();
        const blob = data;
        const file = new Blob([blob], {});
        const filename = 'document-' + Date.now() + '.' + ext;
        saveAs(file, filename);
      });
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(DeleteProcessDialog, {
      width: '400px',
      data: { processId: this.process.id }
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }
  openSubmitDialog(missingStages): void {
    const dialogRef = this.dialog.open(SubmitProcessDialog, {
      width: '650px',
      data: { processId: this.process.id, missingStages: missingStages, process: this.process }
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }
  openUploadDialog(type): void {
    var data = {}
  
    
      data = { uploader: this.uploader, selectedFile: this.selectedFile }
  
  
    const dialogRef = this.dialog.open(UploadExcelDialog, {
      width: '400px',
      data: data
    });
  
    dialogRef.afterClosed().subscribe(result => {
  
    });
  }

  openUploadTablesDialog(type): void {
    var data = {}
  
    
      data = { uploader: this.uploaderTables, selectedFile: this.selectedFile }
  
  
    const dialogRef = this.dialog.open(UploadExcelDialog, {
      width: '400px',
      data: data
    });
  
    dialogRef.afterClosed().subscribe(result => {
  
    });
  }
}





@Component({
  selector: 'process-delete-confirmation-dialog',
  templateUrl: 'delete-process-dialog.html',
})
export class DeleteProcessDialog {
  processId: string;
  constructor(
    public dialogRef: MatDialogRef<DeleteProcessDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, public router: Router,
    private processService: ProcessService) {
    this.processId = data['processId'];
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  deleteProcess() {
    this.dialogRef.close();
    this.processService.delete(this.processId).subscribe
      (data => {
        this.router.navigate(['home']);
      });
  }

}

@Component({
  selector: 'process-submit-confirmation-dialog',
  templateUrl: 'submit-process-dialog.html',
})
export class SubmitProcessDialog implements OnInit {
  processId: string;
  missingStages: string[];
  process;
  constructor(
    public dialogRef: MatDialogRef<SubmitProcessDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, public router: Router,
    private processService: ProcessService, private spinner: NgxSpinnerService, public dialog: MatDialog) {
    this.processId = data['processId'];
    this.missingStages = data['missingStages']
    this.process = data['process']
  }
  processingStages = []
  ngOnInit() {
    this.processService.getProcessingStages(this.processId).subscribe(
      data => {
        
        this.processingStages = data.map(a => a.stages.name)
        this.processingStages = this.processingStages.concat(this.missingStages)
      }
    )
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  submitProcess() {
    this.dialogRef.close();
    this.spinner.show();
    this.processService.submitProcess(this.processId).subscribe
      (data => {
        this.spinner.hide();
        if (data['error']) {
          const dialogRef = this.dialog.open(InfoDialogComponent, {
            width: '500px',
            data: { title: "PROCESS.ERROR_OCCURED", text: "PROCESS.CONTACT_ADMIN" }
          });
        }

        else {
          this.process['submit_receipt'] = data['submit_receipt']
          this.process['status'] = 'Submitted'
          this.process['submit_times'] = data['submit_times']
          const dialogRef = this.dialog.open(InfoDialogComponent, {
            width: '500px',
            data: { title: "PROCESS.DECLARATION_SUBMITTED_MSG1", text: "PROCESS.DECLARATION_SUBMITTED_MSG2" }
          });
          dialogRef.afterClosed().subscribe(result => {
          });
        }


      });
  }
}

@Component({
  selector: 'upload-excel-confirmation-dialog',
  templateUrl: 'upload-excel-dialog.html',
})
export class UploadExcelDialog implements OnInit {
  uploader
  selectedFile: any;
  constructor(
    public dialogRef: MatDialogRef<UploadExcelDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, private spinner: NgxSpinnerService) {
    this.uploader = data['uploader'];
    this.selectedFile = data['selectedFile']
  }
  processingStages = []
  ngOnInit() {

  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  uploadExcel() {
    this.spinner.show();
    this.dialogRef.close();
    this.uploader.uploadAll();
    this.clearSelectedFile();
  }
  clearSelectedFile() {
    this.selectedFile.nativeElement.value = '';
  }
}
