import {AfterViewInit, Component, Input, OnDestroy, OnInit, TemplateRef} from '@angular/core';
import {Router} from "@angular/router";
import {DevelopmentService} from "../../services/development.service";
import { debounceTime, distinctUntilChanged, switchMap, tap, delay } from 'rxjs/operators';
import {IssueDevelop} from "../../entities/issueDevelop";
import {Subject} from "rxjs";
import {PagerDataObject} from "../../entities/pagerDataObject";

@Component({
  selector: 'app-development',
  templateUrl: './development.component.html',
  styleUrls: ['./development.component.css']
})
export class DevelopmentComponent implements OnDestroy, AfterViewInit {
  message: string;
  filter = {"inProgress": true, "toDo": true, "done": true};

  ngAfterViewInit(): void {
    this.dataSub$.next();
  }

  subscription: any;
  data: any;
  private dataSub$ = new Subject<any>();
  tempValue: number;
  searchTerm$ = new Subject<any>();
  text: string = "";

  constructor(public developmentService: DevelopmentService, private router: Router) {
    this.subscription = 
      this.dataSub$.pipe(
        debounceTime(1000),
        switchMap(filter => 
          this.developmentService.getData(filter)
        )
      ).subscribe(data => {
        this.data = data;
      });
    this.developmentService.search(this.searchTerm$, this.filter)
      .subscribe(results => {
        this.data = results;
      });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  issueDevelop: IssueDevelop = new IssueDevelop();
  shiftReady: boolean;
  onKeydown(event, addBtn) {
    if (event.key === "Shift") {
      this.shiftReady = true;
    }else{
      if (event.key === "Enter" && !this.shiftReady) {
        this.addIssue(); addBtn.innerText = 'Add';
      }
      this.shiftReady = false;
    }
  }

  addIssue() {
    if (this.issueDevelop.description != null) {
      this.issueDevelop.status = 1;
      this.developmentService.add(this.issueDevelop)
        .subscribe(issueDevelop => {
          this.issueDevelop = new IssueDevelop();
        });

      this.dataSub$.next();
      this.tempValue = null;
    }
  }

  deleteIssue(id) {
    this.issueDevelop.id = id;
    this.developmentService.delete(this.issueDevelop)
      .subscribe(issueDevelop => {
        this.issueDevelop = new IssueDevelop();
      });

    this.dataSub$.next();
  }

  setStatus(value, id) {
    this.issueDevelop.id = id;
    if (this.tempValue != id) {
      this.issueDevelop.description = null;
    }
    this.issueDevelop.status = value ? 2 : 1;
    this.developmentService.add(this.issueDevelop)
      .subscribe(issueDevelop => {
        this.issueDevelop = new IssueDevelop();
      });
    this.dataSub$.next();
  }

  filterByStatus(value, status) {
    this.filter[status] = value;
    this.searchIssue(this.text);
  }

  startIssue(id) {
    this.issueDevelop.description = null;
    this.issueDevelop.id = id;
    this.issueDevelop.status = 0;
    this.developmentService.add(this.issueDevelop)
      .subscribe(issueDevelop => {
        this.issueDevelop = new IssueDevelop();
      });
    this.dataSub$.next();
  }

  showInTextField(id, value) {
    this.issueDevelop.id = id;
    this.tempValue = id;
    this.issueDevelop.description = value;
  }

  // openModal(template: TemplateRef<any>) {
  //   this.modalRef = this.modalService.show(template, {class: 'modal-sm'});
  // }

  // confirm(id): void {
  //   this.deleteIssue(id);
  //   this.modalRef.hide();
  // }

  // decline(): void {
  //   this.message = 'Declined!';
  //   this.modalRef.hide();
  // }

  searchIssue(issue) {
    if (issue.length === 0) {
      this.dataSub$.next();
    }
    else {
      this.searchTerm$.next(issue);
    }
  }
}
