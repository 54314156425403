import { Directive, HostListener, ElementRef, OnInit } from "@angular/core";
import { ThousandSeparatorPipe } from "../pipes/thousand-separator.pipe";

@Directive({ selector: "[thousandSeparator]" })
export class ThousandSeparatorDirective implements OnInit {

  private el: HTMLInputElement;

  constructor(
    private elementRef: ElementRef,
    private thousandPipe: ThousandSeparatorPipe
  ) {
    this.el = this.elementRef.nativeElement;
  }

  ngOnInit() {
    this.el.value = this.thousandPipe.transform(this.el.value);
  }

  @HostListener("focus", ["$event.target.value"])
  onFocus(value) {
    this.el.value = this.thousandPipe.parse(value); // opossite of transform
  }

  @HostListener("blur", ["$event.target.value"])
  onBlur(value) {
    this.el.value = this.thousandPipe.transform(value);
  }

}