import { Injectable, Output, EventEmitter } from "@angular/core";
import { environment } from "../../environments/environment";
import { Observable, Subject } from "rxjs";

@Injectable()
export class SharedService {
  private lan$ = new Subject<any>();

  apiUrl(pathToAPI: string, parameters = ''): string {
    return environment.apihost + '/' + pathToAPI + '/' + parameters;
  }

  apiUrlLogin(pathToAPI: string, parameters = ''): string {
    return environment.apihost + '/' + pathToAPI
  }

  authServerUrl(pathToAPI: string, parameters = ''): string {
    return environment.authServerhost;
  }

  authServerUrlLogout(): string {
    return environment.authServerhostLogout;
  }

  
  handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);
      return Observable.throw(error.error || 'Server error');
    };
  }



  log(message: string) {
    //this.messageService.add('HeroService: ' + message);
  }

  getLan$(): Observable<any> {
    return this.lan$.asObservable();
  }

  getLan() {
    return localStorage.getItem('default_lan');
  }

  setLan(lan) {
    localStorage.setItem('default_lan', lan);
    this.lan$.next(lan);
  }
  @Output() change: EventEmitter<boolean> = new EventEmitter();
  updateCompany(company) {
    this.change.emit(company);
  }
  @Output() updateToken: EventEmitter<boolean> = new EventEmitter();
  updateAccessToken(){
    this.updateToken.emit(true)
  }
  @Output() confirmDialogEmitter: EventEmitter<boolean> = new EventEmitter();
  confirmDialogAction(obj){
    this.confirmDialogEmitter.emit(obj)
  }

  @Output() confirmProceedDialogEmitter: EventEmitter<boolean> = new EventEmitter();
  confirmDialogActionProceed(obj){
    this.confirmProceedDialogEmitter.emit(obj)
  }

  formSavedEmmiter: EventEmitter<boolean> = new EventEmitter();
  saveForm(obj){
    this.formSavedEmmiter.emit(obj)
  }

  saveSubmitReportEmmiter: EventEmitter<boolean> = new EventEmitter();
  saveSubmitReport(){
    this.saveSubmitReportEmmiter.emit()
  }

}
