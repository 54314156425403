import {Injectable} from "@angular/core";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {SharedService} from "./shared.service";
import {Observable, of} from "rxjs";
import {IssueDevelop} from "../entities/issueDevelop";
import {PagerDataObject} from "../entities/pagerDataObject";
import {catchError, tap} from "rxjs/operators";
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';

const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};

@Injectable()
export class DevelopmentService {

  constructor(private http: HttpClient, private sharedService: SharedService) {
  }

  getData(query: string): Observable<PagerDataObject> {
    let issueDevelop$ = this.http.get<PagerDataObject>(this.sharedService.apiUrl('/issue-develop'))
    return issueDevelop$;
  }

  add(issue: IssueDevelop): Observable<IssueDevelop> {
    return this.http.post<IssueDevelop>(this.sharedService.apiUrl('/issue-develop/add'), issue, httpOptions).pipe(
      tap((issue: IssueDevelop) => this.log(`added issue w/ id=${issue.id}`)),
      catchError(this.handleError<IssueDevelop>('add'))
    );
  }
  delete(issue: IssueDevelop): Observable<any> {
    return this.http.post<IssueDevelop>(this.sharedService.apiUrl('/issue-develop/delete'), issue, httpOptions).pipe(
      tap((issue: IssueDevelop) => this.log(`deleted issue w/ id=${issue.id}`)),
      catchError(this.handleError<IssueDevelop>('add'))
    );
  }

  search(terms: Observable<any>,filter:any) {
    return terms.pipe(debounceTime(400),
      distinctUntilChanged(),
      switchMap(term => this.searchEntries(term,filter)));
  }

  searchEntries(term,filter) {
    var query = "?text="+term+"&inProgress="+filter['inProgress']+"&toDo="+filter['toDo']+"&done="+filter['done']
    return this.http
      .get(this.sharedService.apiUrl('/issue-develop/search')+query)
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  private log(message: string) {
    //this.messageService.add('HeroService: ' + message);
  }
}
