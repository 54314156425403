import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  constructor(private auth: AuthService, private router: Router, public jwtHelper: JwtHelperService) { }


  ngOnInit() {

  }




  getRefreshTime(expTime) {
    var t1 = new Date().getTime() / 1000
    var dif = t1 - expTime;
    return 1000 * (dif * (-1) - 5);
  }
}